@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'),
    url('./assets/fonts/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local('Rubik'), url('./assets/fonts/Rubik-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local('Rubik'), url('./assets/fonts/Rubik-Bold.ttf') format('truetype');
}

* {
  -webkit-font-smoothing: antialiased;
}

html {
  /* background-color: #161313; */
}

/* .goog-te-banner-frame.skiptranslate {
  display: none !important;
} */

/* .goog-logo-link{
    display:none !important;
    }
    .goog-te-gadget{
  color:transparent!important;
  } */

/* .goog-logo-link {
  display: none !important;
}
.goog-te-gadget {
  color: transparent !important;
} */

/*google translate Dropdown */

#google_translate_element select {
  background: rgb(64, 60, 59);
  color: rgb(198, 189, 189);
  border: none;
  border-radius: 3px;
  padding: 6px 8px;
  font-size: 12px !important;
}

.goog-logo-link {
  display: none !important;
}
.goog-te-gadget {
  color: transparent !important;
}

.skiptranslate.goog-te-gadget {
  margin-bottom: -17px;
}

/* .goog-te-banner-frame {
  display: none !important;
} */

body {
  /* background-color: #1c1716; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2500px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu';
}

*:focus {
  outline: none;
}

.lab {
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
}

.socialLogin {
  height: auto;
  opacity: 1;
  transition: all 0.3s ease;
}

.socialLoginHidden {
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  display: none;
}

.p .phoneInp .PhoneInput {
  background: rgba(45, 45, 45, 0.05);
  border-radius: 13px;
  width: 100%;
  height: 55px;
  padding: 0 20px;
}

.phoneInp .PhoneInput input {
  background: none;
  border: none;
  font-size: 17px;
  color: #2d2d2d;
  opacity: 0.5;
}

.phoneCode .react-code-input > * {
  width: 55px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 60px !important;
  padding-left: 7px !important;
  background-color: rgba(45, 45, 45, 0) !important;
  color: #b03a5e !important;
  border: 2px solid #a5a5a5 !important;
  border-radius: 8px !important;
  font-size: 25px !important;
  text-align: center !important;
  margin: 7px !important;
  padding: 0px !important;
  box-shadow: none !important;
}

@media (max-width: 800px) {
  .phoneCode .react-code-input > * {
    width: 50px !important;
    height: 55px !important;
    margin: 4px !important;
  }
}

@media (max-width: 345px) {
  .phoneCode .react-code-input > * {
    width: 50px !important;
    height: 55px !important;
    margin: 4px !important;
  }
}

@media (max-width: 280px) {
  .phoneCode .react-code-input > * {
    width: 50px !important;
    height: 55px !important;
    margin: 4px !important;
  }
}

.phoneCode {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.phoneCode.codeAuth .react-code-input > * {
  width: 50px !important;
  margin: 5px !important;
}

.phoneCode.codeAuth {
  margin-bottom: 5px;
  margin-top: 10px;
}

.phoneCode.digits7 .react-code-input > * {
  width: 45px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.fw-5 {
  font-weight: 500;
}

a {
  text-decoration: none;
}

.draw .rbd-j687wtz7x1-dr {
  background: none !important;
  backdrop-filter: blur(50px) !important;
}

/* #delete-container {
  overflow: visible !important;
} */

.cam {
  border-radius: 10px;
  overflow: hidden;
  border: 3px dashed #fa8d11;
  /* width: 311px;
  height: 200px; */
  /* padding-bottom: -100px; */
}

/* .cam > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.camID {
  border-radius: 8px;
  overflow: hidden;
  border: 3px dashed #fa8d11;
  width: 100%;
  /* height: 200px; */
  /* padding-bottom: -100px; */
}

.camID > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camID video {
  vertical-align: middle;
}

.cam-passport {
  border-radius: 10px;
  overflow: hidden;
  border: 3px dashed #fa8d11;
  width: 311px;
  height: 380px;
}

.cam-passport > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camCaptured {
  border-radius: 8px;
  overflow: hidden;
  border: 3px solid #fa8d11;
}

.camCaptured img {
  vertical-align: middle;
}

.camCaptured img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camCaptured-passport {
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid #fa8d11;
  width: 311px;
  height: 380px;
}

.camCaptured-passport img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camCircle {
  border-radius: 50%;
  overflow: hidden;
  border: 3px dashed #fa8d11;
  width: 311px;
  height: 311px;
  z-index: 100;
}

@media (max-width: 800px) {
  .camCircle {
    width: 220px;
    height: 220px;
  }
}

.camCapturedCircle {
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fa8d11;
  height: 311px;
  width: 311px;
}

@media (max-width: 800px) {
  .camCapturedCircle {
    width: 220px;
    height: 220px;
  }
}

.camCapturedCircle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .rdrDayNumber {
  color: #fa8d11 !important;
}

.rdrSelected {
  color: #fa8d11 !important;
}

.rdrDay.rdrDayToday .rdrDayNumber span::after {
  background: #fa8d11 !important;
}

.rdrDay {
  color: #fa8d11 !important;
}

.rdrDayStartPreview.rdrDayEndPreview {
  color: #fa8d11 !important;
}

.rdrMonth {
  width: 100% !important;
}

.rdrCalendarWrapper {
  width: 100% !important;
  border-radius: 16px !important;
} */

/* input field animation */

.shake-input {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  border: 1px solid red !important;
  border-radius: 13px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* phone input */
.form-control {
  border: 1px solid #e1dbdb !important;
  box-sizing: border-box !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05) !important;
  height: 52px !important;
  background: #fefefe !important;
  border-radius: 8px !important;
  padding-left: 60px !important;
  font-size: 17px !important;
  color: #2d2d2d !important;
  /* opacity: 0.5 !important; */
}

.flag-dropdown {
  border: none !important;
  border-radius: 13px !important;
  padding-left: 10px !important;
  background: none !important;
}

.open {
  background: none !important;
}

.selected-flag {
  background: none !important;
}

input[type='file'] {
  display: none;
}

.custom-file-upload {
  cursor: pointer;
  border: none;
  color: #fff;
  height: 55px !important;
  width: 100%;
  background: linear-gradient(132.33deg, #d24074 -0.67%, #1268c3 102.54%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
}

.custom-file-upload:active {
  transform: translateY(3px);
}

/* appear - on page load */
.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 5000ms linear 5000ms;
}

/* exit */
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 5000ms linear;
}
.fade-exit-done {
  opacity: 0;
}

/* addDocumentID webCam style settings */

/* .add-document-web-cam {
  width: 100%;
  height: 200px;
} */

.sidebarNav {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* color: #2d2d2d; */
  margin-bottom: 16px;
  cursor: pointer;
  color: #fefefe !important;
}

.sidebarNavActive {
  color: #ff5700 !important;
}

.desktopNav {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  margin: 0 16px;
  opacity: 0.7;
  color: #2d2d2d !important;
}

.desktopNavActive {
  color: #ff5700 !important;
}

.desktopNavPartner {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  margin: 0 16px;
  /* opacity: 0.7; */
  color: #fefefe !important;
}

.desktopNavActivePartner {
  color: #ff5700 !important;
}

.news-comment {
  /* width: 311px;
  height: 100px; */
  max-width: 311px;
  min-width: 311px;
  max-height: 100px;
  min-height: 100px;
  border: none;
  resize: none;
  border-radius: 10px;
  background-color: #2d2d2d0d;
}
.disabled-link {
  pointer-events: none;
}

.nnn {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.upArrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.navigation {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  /* position: sticky; */
  /* top: 10px; */
  z-index: 9000;
  border-radius: 15px;
  transition: all 0.1s ease;
}

.navigation.active {
  background-color: #fff;
  /* margin: 0 -5px; */

  height: 57px;
  padding: 0 20px;
  position: sticky;
  top: 10px;
  margin-left: -10px;
  margin-right: -10px;

  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.05);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* .city-input {
  margin-left: -5px !important;
} */

.form-control {
  margin-right: 0 !important;
  width: 100% !important;
}

.checkbox:checked::before {
  background-color: green;
}

.news-bullet {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
  opacity: 0.7;
  margin: 0 5px 0 10px;
}

.jumping-dots {
  display: flex;
  min-height: 22px;
  align-items: center;
}

.jumping-dots div {
  position: relative;
  bottom: 0px;
  animation: jump 2s infinite;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fefefe;
}

.jumping-dots .dot-1 {
  animation-delay: 200ms;
  margin-right: 4px;
  opacity: 0.75;
}
.jumping-dots .dot-2 {
  animation-delay: 400ms;
  margin-right: 4px;
}
.jumping-dots .dot-3 {
  animation-delay: 600ms;
  opacity: 0.5;
}

.css-1uccc91-singleValue {
  overflow: visible !important;
}

.swiper-pagination-bullet {
  visibility: hidden;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  color: white !important;
  left: 0px !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 0px !important;
  color: white !important;
}

@keyframes jump {
  0% {
    bottom: 0px;
  }
  20% {
    bottom: 5px;
  }
  40% {
    bottom: 0px;
  }
}

.mySwiper .swiper-button-next:after,
.mySwiper .swiper-button-prev:after {
  font-size: 20px !important;
}

@media (max-width: 450px) {
  .mySwiper .swiper-button-next:after,
  .mySwiper .swiper-button-prev:after {
    display: none;
  }
}

.packageSwiper .swiper-button-next:after,
.packageSwiper .swiper-button-prev:after {
  font-size: 30px !important;
  color: #fa8d11 !important;
}

@media (max-width: 450px) {
  .packageSwiper .swiper-button-next:after,
  .packageSwiper .swiper-button-prev:after {
    display: none;
  }
}

.mySwiper .swiper-button-next {
  visibility: hidden;
}

.mySwiper .swiper-button-prev {
  visibility: hidden;
}

.mySwiper:hover .swiper-button-next {
  visibility: visible;
}

.mySwiper:hover .swiper-button-prev {
  visibility: visible;
}

.packageSwiper .swiper-button-next {
  visibility: hidden;
}

.packageSwiper .swiper-button-prev {
  visibility: hidden;
}

.packageSwiper:hover .swiper-button-next {
  visibility: visible;
}

.packageSwiper:hover .swiper-button-prev {
  visibility: visible;
}

/* .swiper-container {
  width: 100%;
} */

/* .swiper-wrapper {
  width: 100% !important;
} */

/* .swiper-wrapper .swiper-slide-active {
  width: 100% !important;
} */

/* .swiper-slide {
  width: 100% !important;
} */

.packageSwiper .swiper-button-next,
.packageSwiper .swiper-button-prev {
  top: 30% !important;
}

.productsSwiper .swiper-button-next:after,
.productsSwiper .swiper-button-prev:after {
  font-size: 30px !important;
  color: #fa8d11 !important;
}

@media (max-width: 450px) {
  .productsSwiper .swiper-button-next:after,
  .productsSwiper .swiper-button-prev:after {
    display: none;
  }
}

.productsSwiper .swiper-button-next {
  visibility: hidden;
}

.productsSwiper .swiper-button-prev {
  visibility: hidden;
}

.productsSwiper:hover .swiper-button-next {
  visibility: visible;
}

.productsSwiper:hover .swiper-button-prev {
  visibility: visible;
}

.packageSwiper .swiper-wrapper .swiper-slide,
.productsSwiper .swiper-wrapper .swiper-slide {
  width: 80% !important;
}

.desktopHomeSwiper .swiper-button-next {
  margin-right: 10px;
}

.desktopHomeSwiper .swiper-button-prev {
  margin-left: 10px;
}

.desktopHomeSwiper .swiper-pagination-bullet {
  visibility: visible;
}

.desktopHomeSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: linear-gradient(
    160.43deg,
    #f58e35 1.87%,
    #f26c6b 50.93%,
    #ee288a 99.33%
  ) !important;
}

.desktopHomeSwiper .swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  background: #bdbdbd !important;
  opacity: 1 !important;
  margin: 0 7px !important;
}

.desktopHomeSwiper .swiper-button-next {
  color: #bdbdbd !important;
}

.desktopHomeSwiper .swiper-button-prev {
  color: #bdbdbd !important;
}

.shopSwiper .swiper-pagination-bullet {
  visibility: hidden;
}

.shopSwiperMobile .swiper-pagination-bullet {
  visibility: hidden;
}

.shopSwiperMobile .swiper-slide {
  width: 80%;
}

.campaignSwiperMobile .swiper-pagination-bullet {
  visibility: hidden;
}

.campaignSwiperMobile .swiper-slide {
  width: 90% !important;
}

.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000;
  /* border: 1px solid #ccc; */
  background: #fff;
  /* overflow: auto; */
  border-radius: 8px;
  outline: none;
  padding: 40px;
  max-width: 90%;
  width: 750px;
}

.referralModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  /* border: 1px solid #ccc; */
  background: #fff;
  /* overflow: auto; */
  border-radius: 8px;
  outline: none;
  padding: 40px;
  max-width: 1350px;
  width: 750px !important;
  max-width: 90% !important;
  /* height: 80% !important; */
}

.cameraModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  /* border: 1px solid #ccc; */
  background: #fff;
  /* overflow: auto; */
  border-radius: 8px;
  outline: none;
  padding: 40px;
  max-width: 1350px;
  width: 700px !important;
  max-width: 90% !important;
  /* height: 80% !important; */
}

@media (max-width: 800px) {
  .cameraModal {
    padding: 20px;
  }
}

.cameraModalPassport {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  /* border: 1px solid #ccc; */
  background: #fff;
  /* overflow: auto; */
  border-radius: 8px;
  outline: none;
  padding: 40px;
  max-width: 1350px;
  width: 500px !important;
  max-width: 90% !important;
  /* height: 80% !important; */
}

@media (max-width: 800px) {
  .cameraModalPassport {
    padding: 20px;
  }
}

.imgModal {
  /* position: fixed; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 1000000;
  /* border: 1px solid #ccc; */
  background: #fff;
  /* overflow: auto; */
  outline: none;
  width: 100vw;
  height: 100vh !important;
  user-select: none;
}

.referralInput {
  /* width: 236px; */
  width: 100%;
  height: 55px;
  margin: 0;

  padding: 18px 10px 18px 18px;
  font-size: 17px;
  border-radius: 8px;
  border: 1px solid #ff5700;
  outline: none;
}

.floatCartMobileModal {
  /* position: fixed; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 1000000;
  /* border: 1px solid #ccc; */
  background: #fdede2;
  /* overflow: auto; */
  outline: none;
  width: 100vw;
  height: 100vh !important;
  user-select: none;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(196, 196, 196, 0.4);
  z-index: 1000000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modalHeader {
  text-align: right;
}

.modalContent {
  max-height: 80vh;
  overflow-y: auto;
}

.modalProfileMenu {
  position: fixed;
  top: 80px;
  right: 50px;
  z-index: 1000000;
  background: #fff;
  border-radius: 8px;
  outline: none;
  padding: 10px;
  /* transform: translate(-50%, -50%); */
  /* overflow: auto; */
  /* max-width: 95%; */
  /* width: 90% !important; */
}

@media (max-width: 800px) {
  .modalProfileMenu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    width: 90%;
    max-width: 445px;
  }
}

.billingInput {
  background: rgba(45, 45, 45, 0.05);
  border-radius: 13px;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
  justify-content: space-between;
  border: none;
  outline: none;
  font-size: 17px;
}

.cardInput {
  /* background: rgba(45, 45, 45, 0.05); */
  border-radius: 8px;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 16px;
  justify-content: space-between;
  border: none;
  outline: none;
  font-size: 17px;
}

.react-simple-image-viewer__next {
  color: #2d2d2d !important;
  opacity: 0.3 !important;
}

.react-simple-image-viewer__previous {
  color: #2d2d2d !important;
  opacity: 0.3 !important;
}

.react-simple-image-viewer__close {
  color: #2d2d2d !important;
  opacity: 0.3 !important;
}

.react-simple-image-viewer__next:hover {
  opacity: 0.9 !important;
}

.react-simple-image-viewer__previous:hover {
  opacity: 0.9 !important;
}

.react-simple-image-viewer__close:hover {
  opacity: 0.9 !important;
}
.react-simple-image-viewer__slide img {
  max-height: calc(100% - 20px) !important;
  margin: 20px !important;
  border-radius: 13px !important;
}

.gradientText {
  /* background: -webkit-linear-gradient(
    160.43deg,
    #ee288a 1.87%,
    #f26c6b 50.93%,
    #f58e35 99.33%
  ); */
  color: #ff0084 !important;
  /* -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.gradientTextOrange {
  color: #ff5700 !important;
}

.textGreen {
  color: #a6f787 !important;
}

.textGradient {
  background: -webkit-linear-gradient(
    270deg,
    rgba(245, 142, 53, 1) 1.87%,
    rgba(242, 108, 107, 1) 50.93%,
    rgba(238, 40, 138, 1) 99.33%
  );
  color: #ff0084 !important;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tooltip {
  display: none;
  opacity: 0;
  transition: all 0.3s ease;
}

.tooltipParent {
  position: relative;
}

.tooltipParent:hover .tooltip {
  display: flex;
  opacity: 1;
}

.gradientGivvoTextColor {
  background: #ff5700;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.activeImage img {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
}

/* .drag {
  visibility: hidden;
  transition: all 0.5s ease;
  opacity: 0;
}

.dragWrap:hover .drag {
  visibility: visible;
  opacity: 1;
} */
/* 
.drag {
  cursor: url('./assets/icons/Drag2.png') 35 35, auto !important;
} */

/* .enterNow {
  cursor: url('./assets/icons/enterNowCursor.png') 53 53, auto !important;
} */

.noScrollBar::-webkit-scrollbar {
  display: none;
}

.noScrollBar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.hoverPrize {
  opacity: 0;
  transition: all 0.3s ease;
  background-image: linear-gradient(
    180deg,
    rgba(47, 47, 47, 0) 0%,
    rgba(27, 27, 27, 0.8) 100%
  );
}

.hoverPrizeWrap:hover .hoverPrize {
  opacity: 1;
}
.hoverPrizeBtns {
  opacity: 0;
  transition: all 0.3s ease;
}

.hoverPrizeWrap:hover .hoverPrizeBtns {
  opacity: 1;
}

.transitionSlider > * {
  transition: all 0.5s ease !important;
}

.hoverHeight {
  height: 0 !important;
  overflow: hidden !important;
}

.hoverHeightWrap:hover .hoverHeight {
  height: 34px !important;
}

.disabled-link {
  pointer-events: none;
}

.coupon {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg width='502' height='769' viewBox='0 0 502 769' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Rectangle 1999' filter='url(%23filter0_d_1082_23029)'%3E%3Cpath d='M24 24C24 17.3726 29.3726 12 36 12H466C472.627 12 478 17.3726 478 24V501.975C478 501.975 457 502.991 457 526.855C457 550.719 478 551.734 478 551.734V721C478 727.627 472.627 733 466 733H36C29.3726 733 24 727.627 24 721V551.734C24 551.734 45 550.719 45 526.855C45 502.991 24 501.975 24 501.975V24Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_1082_23029' x='0' y='0' width='100%' height='769' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='12'/%3E%3CfeGaussianBlur stdDeviation='12'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1082_23029'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1082_23029' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
}

.custom-file-upload-kyc {
  cursor: pointer;
  border: none;
  font-size: 17px;
  color: #ff5700;
  height: 55px !important;
  width: calc(100% - 20px);
  background: #fff;
  border-radius: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 34px !important;
  width: 111px;
  border: 1px solid #ff5700;
}

.custom-file-upload-kyc:active {
  transform: translateY(3px);
}

.disabledCursor {
  cursor: default;
}

/* Style the list */
ul.breadcrumb {
  padding: 10px 0;
  list-style: none;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  content: '>';
}

.welcome-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: #272322;
  border-radius: 12px;
  outline: none;
  padding: 40px;
}

.welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(196, 196, 196, 0.4);
  z-index: 1000000;
}

/* .ReactModal__Overlay--after-open {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
} */

.fadeDiv {
  position: relative;
}

.settingsNavActive div {
  background-color: #f3f3f3;
}

/* TRUNCATE */

.truncated {
  display: flex;
  flex: 1;
  min-width: 0;
}

.truncated div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .hoverforshow:hover .showForHovered {
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 500ms !important;
  margin-bottom: 20px !important;
} */

.gradientBackground {
  background: -webkit-linear-gradient(
    270deg,
    rgba(245, 142, 53, 1) 1.87%,
    rgba(242, 108, 107, 1) 50.93%,
    rgba(238, 40, 138, 1) 99.33%
  );
}

.svgColor > * {
  fill: #1268c3 !important;
}

.__react_component_tooltip {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
}
